import * as React from 'react';
import { Pre } from '@blueprintjs/core';
import { ILogMessage } from '../types';
import { isoTimeStampToHumanReadable } from '../utils/renderResults';
import { Collapsable } from './common';

interface IProps {
    logMessages: ILogMessage[];
    isOpenByDefault?: boolean;
}

const AppLog: React.FC<IProps> = (props) => (
    <Collapsable title='Application log' isOpenByDefault={props.isOpenByDefault}>
        <AppLogContent logMessages={props.logMessages}/>
    </Collapsable>
)

const AppLogContent: React.FC<{ logMessages: ILogMessage[]; }> = ({ logMessages }) => {
    const elementId = 'application-log';
    React.useEffect(() => {
        const scrollDiv = document.getElementById(elementId);
        if (scrollDiv !== null) {
            scrollDiv.scrollTo({ top: scrollDiv.scrollHeight });
        }
        // The useEffect function is called when the messages prop is updated
    }, [logMessages]);

    return (
        <Pre id={elementId} style={{ maxHeight: 150, overflowY: 'scroll' }} className='shadow-none m-0'>
            {logMessages.map(message =>
                `[${isoTimeStampToHumanReadable(message.timestamp)}] ${limitMessage(message.message, 500)}\n`
            )}
        </Pre>
    )
};

function limitMessage(message: string, maxLength: number) {
    if (message.length > maxLength) {
        return (
            `${message.slice(0, maxLength)} [This log line has been truncated to the first ${maxLength} characters]`);
    }
    return message;
}

export default AppLog;
