import { FieldProps } from 'formik';
import React from 'react';
import clsx from 'clsx';
import { saveFileListToFormikField } from './utils';
import { DocumentIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { byteSizeToHumanReadable } from '@utils';

interface IProps extends FieldProps {}

const buttonStyle =
    'w-full rounded bg-white py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 flex justify-center disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none';

export default function DragAndDropArea(props: IProps) {
    const { field, form } = props;
    const fileList = typeof field.value === 'object' ? (field.value as FileList) : null;
    const firstFile = fileList ? fileList.item(0) : null;

    const [isDrag, setIsDrag] = React.useState<boolean>(false);
    const fileInputRef = React.createRef() as React.RefObject<HTMLInputElement>;

    const handleOpenFileSelector = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleClearFile = () => {
        form.setFieldValue(field.name, '');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDrag(false);
        const files = event.dataTransfer.files;
        saveFileListToFormikField(files, field, form, false);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        setIsDrag(true);
        event.dataTransfer.dropEffect = 'copy';
    };

    return (
        <div className='flex space-x-2'>
            <div
                className={clsx(
                    'flex-grow flex flex-col border border-dotted justify-center items-center rounded-md',
                    isDrag ? 'border-2 border-emerald-500' : 'border border-gray-300'
                )}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={() => setIsDrag(false)}
            >
                <input
                    alt='Select Text File'
                    hidden
                    multiple={false}
                    name='text-file-input'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        saveFileListToFormikField(event.currentTarget.files, field, form, false)
                    }
                    ref={fileInputRef}
                    type='file'
                />
                <div className='flex items-center'>
                    {!firstFile ? (
                        <>
                            <h2 className='text-lg text-gray-500 select-none my-auto pr-2'>Drag and drop</h2>
                            <CloudArrowUpIcon className='h-9 text-gray-500' />
                        </>
                    ) : (
                        <div>{firstFile.name} - {byteSizeToHumanReadable(firstFile.size)}</div>
                    )}
                </div>
            </div>
            <div className='w-36 flex flex-col space-y-2'>
                <button className={buttonStyle} onClick={handleOpenFileSelector}>
                    <DocumentIcon className='h-4 w-4 mr-1' aria-hidden='true' />
                    Select File
                </button>
                <button className={buttonStyle} onClick={handleClearFile} disabled={!firstFile}>
                    <TrashIcon className='h-4 w-4 mr-1' aria-hidden='true' />
                    Clear File
                </button>
            </div>
        </div>
    );
}
