import { AccountUuid } from '../Account.types';
import { ImgproxyUrl, S3Url } from '../common.types';
import { IAppVersion, IAppVersionSlim } from './AppVersion.types';

export type AppUuid = string;

export enum AppState {
    draft = 'draft',
    public = 'public',
}

export enum ResourceType {
    app = 'app',
    dataRecord = 'data-record',
    experiment = 'experiment',
}

export interface IApp {
    account_display_name: string;
    account_handle: string;
    account_id: AccountUuid;
    account_profile_picture: ImgproxyUrl;
    account_profile_picture_200x200: ImgproxyUrl;
    active_version: IAppVersion;
    allow_client_side_execution: boolean;
    app_versions: IAppVersionSlim[];
    created_at: string;
    is_editable_by_user: boolean;
    is_license_required: boolean;
    is_open_source: boolean;
    is_unlisted: boolean;
    name: string;
    public_id: AppUuid;
    resource_name_prefix: string;
    resource_uri: string;
    state: AppState;
    type: ResourceType;
    visualization_prefer_full_width: boolean;
}

export interface ISlimApp {
    account_display_name: string;
    account_handle: string;
    account_profile_picture: S3Url;
    active_version: string;
    created_at: string;
    description: string;
    is_license_required: boolean;
    is_unlisted: boolean;
    name: string;
    public_id: AppUuid;
    state: AppState;
}

export interface IAppMetadata {
    account_id: AccountUuid;
    allow_client_side_execution: boolean;
    is_license_required: boolean;
    is_open_source: boolean;
    is_unlisted: boolean;
    name: string;
    state: AppState;
}

export interface IAppPost extends Partial<IAppMetadata> {
    account_id: AccountUuid;
    name: string;
    source_files_zip: Blob;
}

export interface IAppForkPost extends Partial<IAppMetadata> {
    account_id: AccountUuid;
    name: string;
    app_to_fork: {
        account_handle: string;
        name: string;
    };
}
