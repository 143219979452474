import { ArgumentType, ISetting, ISettingOption } from '@biolibtech/biolib-js';
import { ISettingValues, SettingValue } from './types';
import { FormikErrors } from 'formik';
import { validateSequencesAndReturnErrorMessage } from './sequenceValidationUtils';

export function getSettingValues(settings: ISetting[]): ISettingValues {
    let prefilledInputMap: Record<string, string> = {};
    try {
        const hashInputPrefix = '#input=';
        if (location.hash.startsWith(hashInputPrefix)) {
            const inputBase64 = location.hash.slice(hashInputPrefix.length);
            prefilledInputMap = JSON.parse(atob(inputBase64));
        }
    } catch (error) {
        console.error('Failed to parse location hash for input prefilling. Got error: ', error);
    }

    let settingValues: ISettingValues = {};
    for (const setting of settings) {
        switch (setting.render_type) {
            case ArgumentType.group:
                const groupValues: ISettingValues[] = [];

                if (setting.default_value) {
                    for (const defaultGroupValue of setting.default_value.split(setting.group_separator)) {
                        const defaultValues = defaultGroupValue.split(setting.group_argument_separator);
                        const values: ISettingValues = {};
                        for (let j = 0; j < setting.group_arguments.length; j += 1) {
                            const groupSetting = setting.group_arguments[j];
                            values[groupSetting.public_id] = defaultValues[j] ?? '';
                        }
                        groupValues.push(values);
                    }
                } else {
                    const values: ISettingValues = {};
                    for (let j = 0; j < setting.group_arguments.length; j += 1) {
                        const groupSetting = setting.group_arguments[j];
                        values[groupSetting.public_id] = '';
                    }
                    groupValues.push(values);
                }

                settingValues[setting.public_id] = groupValues;
                break;

            case ArgumentType.toggle: {
                const offOption = setting.options.find((option) => option.key === 'off');
                settingValues[setting.public_id] = setting.default_value ?
                    setting.default_value : offOption ? offOption.value : 'off';
                break;
            }

            case ArgumentType.multiselect: {
                const defaultOptions: ISettingOption[] = [];
                for (const value of setting.default_value.split(',')) {
                    const option = setting.options.find((option) => option.value === value);
                    if (option) {
                        defaultOptions.push(option);
                    }
                }
                settingValues[setting.public_id] = defaultOptions;
                break;
            }

            case ArgumentType.radio:
            case ArgumentType.dropdown:
                settingValues[setting.public_id] = setting.default_value;
                break;

            // For other types e.g. text and file only show default value as placeholder,
            // which is done in RenderSettings
            default:
                settingValues[setting.public_id] = '';
                break;
        }

        if (setting.sub_arguments.length > 0) {
            settingValues = {
                ...settingValues,
                ...getSettingValues(setting.sub_arguments),
            };
        }

        if (typeof prefilledInputMap[setting.key] === 'string') {
            settingValues[setting.public_id] = prefilledInputMap[setting.key];
        }
    }
    return settingValues;
}

export interface ISettingWithParentUuid extends ISetting {
    parent_setting_uuid: null | string;
}

export function getFlatSettingsDict(settings: ISetting[], parent_setting_uuid: null | string = null): Record<string, ISettingWithParentUuid> {
    let settingsDict: Record<string, ISettingWithParentUuid> = {};
    for (const setting of settings) {
        settingsDict = {
            ...settingsDict,
            [setting.public_id]: { ...setting, parent_setting_uuid },
            ...getFlatSettingsDict(setting.sub_arguments, setting.public_id),
            ...getFlatSettingsDict(setting.group_arguments, setting.public_id),
        };
    }
    return settingsDict;
}


export async function validateSettings(
    settingsDict: Record<string, ISettingWithParentUuid>,
    settingValues: ISettingValues,
): Promise<FormikErrors<ISettingValues>> {
    let errors: FormikErrors<ISettingValues> = {};
    for (const [settingId, value] of Object.entries(settingValues)) {
        try {
            const setting = settingsDict[settingId];
            if (setting.render_type === ArgumentType.hidden) {
                continue;
            }
            const subArgumentParentValue = settingValues[setting.parent_setting_uuid] as undefined | SettingValue;
            if (setting.sub_argument_condition && subArgumentParentValue !== setting.sub_argument_condition) {
                continue;
            }

            if (Array.isArray(value)) {
                if (setting.required && value.length === 0) {
                    errors[settingId] = 'Required';
                } else if (setting.render_type === ArgumentType.group) {
                    if (setting.required && value.length === 0) {
                        errors[settingId] = 'Required';
                    } else {
                        for (let i = 0; i < value.length; i += 1) {
                            const groupArgumentErrors = await validateSettings(settingsDict, value[i] as ISettingValues);
                            if (Object.keys(groupArgumentErrors).length > 0) {
                                if (!errors[settingId]) {
                                    // @ts-ignore
                                    errors[settingId] = {};
                                }
                                // @ts-ignore
                                errors[settingId][i] = groupArgumentErrors;
                            }
                        }
                    }
                }
            } else if (setting.required && !setting.default_value && !value) {
                errors[settingId] = 'Required';
            } else if (setting.render_type === ArgumentType.sequence) {
                const errorMessage = await validateSequencesAndReturnErrorMessage(value);
                if (errorMessage) {
                    errors[settingId] = errorMessage;
                }
            }
        } catch (error) {
            console.error(
                `Failed to validate setting "${settingId}" with value ${value}. Hit error: `,
                error,
                settingsDict,
            );
        }
    }
    return errors;
}
