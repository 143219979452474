import * as React from 'react';
import App from './App';
import appRunSlice from '../appRunSlice';
import ConfirmClose from '../../../components/ConfirmClose';
import MinimizedAppsDrawer from '../../../components/MinimizedAppsDrawer';
import { Classes, Overlay } from '@blueprintjs/core';
import { JobStatus } from '../types';
import * as classNames from 'classnames';
import { IRootState } from '../../../state/rootReducer';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

const mapStateToProps = (state: IRootState) => ({
    jobIdOpenInPage: state.appRun.jobIdOpenInPage,
    jobIdOpenInModal: state.appRun.jobIdOpenInModal,
    jobWrapperDict: state.appRun.jobWrapperDict,
    openAppPayloadAwaitingAcceptTerms: state.appRun.openAppPayloadAwaitingAcceptTerms,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        quitAppAwaitingAcceptTerms: appRunSlice.actions.quitAppAwaitingAcceptTerms,
        quitJob: appRunSlice.actions.quitJob,
        runJob: appRunSlice.actions.runJob,
        setHasGuestAcceptedTermsToTrue: appRunSlice.actions.setHasGuestAcceptedTermsToTrue,
        setJobIdOpenInModal: appRunSlice.actions.setJobIdOpenInModal,
    },
    dispatch
);

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface IProps extends ReduxProps {

}

interface IState {
    acceptedTerms: boolean;
    isDrawerOpen: boolean;
    isConfirmCloseOpen: boolean;
}

class AppOverlay extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            acceptedTerms: false,
            isDrawerOpen: false,
            isConfirmCloseOpen: false,
        };
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        const { jobIdOpenInModal, jobWrapperDict } = this.props;
        if (!prevProps.jobIdOpenInModal && jobIdOpenInModal && jobWrapperDict[jobIdOpenInModal]) {
            document.body.classList.add('no-scroll');
        }

        if (prevProps.jobIdOpenInModal && !jobIdOpenInModal) {
            document.body.classList.remove('no-scroll');
        }
    }

    public render() {
        const { jobIdOpenInModal, jobIdOpenInPage, jobWrapperDict, openAppPayloadAwaitingAcceptTerms } = this.props;
        const jobWrapperArray = Object.values(jobWrapperDict).filter(({ job }) => job.public_id !== jobIdOpenInPage);

        const openAppJobWrapper = jobIdOpenInModal ? jobWrapperDict[jobIdOpenInModal] : null;

        const openApp = openAppJobWrapper ?
            openAppJobWrapper.app : openAppPayloadAwaitingAcceptTerms ? openAppPayloadAwaitingAcceptTerms.app : null;

        const appName = openApp ? openApp.name : '';
        const isAnyJobComputing = jobWrapperArray.some((jobWrapper) => jobWrapper.status === JobStatus.computing);

        return (
            <div className='absolute'>
                <Overlay
                    isOpen={!!openApp}
                    autoFocus={true}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    enforceFocus={true}
                    hasBackdrop={true}
                    usePortal={true}
                    onClose={this.handleMinimizeApp}
                    className={classNames('', Classes.OVERLAY_CONTAINER)}
                >
                    <section className='w-screen overlay-transition'>
                        <div className='container'>
                            <ConfirmClose
                                title={`Quit ${appName}?`}
                                message={`Are you sure you want to quit ${appName}?
Any progress or data will be lost.`}
                                isOpen={this.state.isConfirmCloseOpen}
                                handleConfirmQuit={this.handleConfirmQuit}
                                handleAbortQuit={this.handleAbortQuit}
                            />
                            <App
                                isOpenInModal={true}
                                openAppPayloadAwaitingAcceptTerms={openAppPayloadAwaitingAcceptTerms}
                                handleAcceptTerms={this.props.setHasGuestAcceptedTermsToTrue}
                                handleConfirmQuit={this.handleConfirmQuit}
                                handleMinimizeApp={this.handleMinimizeApp}
                                handleQuitApp={this.handleQuitApp}
                                jobWrapper={openAppJobWrapper}
                                runJob={this.props.runJob}
                            />
                        </div>
                    </section>
                </Overlay>
                <MinimizedAppsDrawer
                    isOpen={this.state.isDrawerOpen}
                    jobWrapperArray={jobWrapperArray}
                    onClose={this.handleDrawerClose}
                    onOpenJob={this.handleOpenJob}
                />
            </div>
        );
    }

    private handleAbortQuit = (): void => {
        this.setState({ ...this.state, isConfirmCloseOpen: false });
    };

    private handleConfirmQuit = (): void => {
        this.setState({ ...this.state, isConfirmCloseOpen: false });

        // Delay closing app so the confirm close dialog disappears first
        setTimeout(() => {
            if (this.props.openAppPayloadAwaitingAcceptTerms) {
                this.props.quitAppAwaitingAcceptTerms();
            }
            if (this.props.jobIdOpenInModal) {
                this.props.quitJob({ jobId: this.props.jobIdOpenInModal });
            }
        }, 100)
    };

    private handleQuitApp = (): void => {
        this.setState({ ...this.state, isConfirmCloseOpen: true });
    };

    private handleMinimizeApp = (): void => {
        if (this.props.openAppPayloadAwaitingAcceptTerms) {
            this.props.quitAppAwaitingAcceptTerms();
        }
        this.props.setJobIdOpenInModal({ jobIdOpenInModal: null });
    };

    private handleOpenJob = (jobIdOpenInModal: string): void => {
        this.handleDrawerClose();
        this.props.setJobIdOpenInModal({ jobIdOpenInModal });
    };

    private handleDrawerOpen = () => this.setState({ ...this.state, isDrawerOpen: true });
    private handleDrawerClose = () => this.setState({ ...this.state, isDrawerOpen: false });
}

export default connect(mapStateToProps, mapDispatchToProps)(AppOverlay);
