import { ArgumentType, ISetting } from '@biolibtech/biolib-js';
import { FormGroup, Intent } from '@blueprintjs/core';
import { Field, FieldProps } from 'formik';
import React from 'react';
import RenderGroupArguments from './RenderGroupArguments';
import RenderSetting from './RenderSetting';

interface IProps {
    settings: ISetting[];
}

export default function RenderSettings(props: IProps) {
    const { settings } = props;
    return (
        <>
            {settings.map((setting) => {
                if (setting.render_type === ArgumentType.hidden) {
                    return null;
                }
                return (
                    <Field key={setting.public_id} name={setting.public_id}>
                        {(fieldProps: FieldProps) => {
                            const { meta, field, form } = fieldProps;
                            const { touched, error } = meta;
                            const subArgumentsToRender = setting.sub_arguments.filter(({ sub_argument_condition }) =>
                                sub_argument_condition === fieldProps.field.value
                            );
                            return (
                                <>
                                    <FormGroup
                                        helperText={touched && error && typeof error === 'string' ? error : ''}
                                        intent={touched && error ? Intent.DANGER : Intent.NONE}
                                        label={<b>{setting.description}</b>}
                                    >
                                        {setting.render_type === ArgumentType.group ?
                                            <RenderGroupArguments
                                                settings={setting.group_arguments}
                                                fieldProps={fieldProps}
                                            /> :
                                            <RenderSetting setting={setting} fieldProps={fieldProps}/>
                                        }
                                    </FormGroup>
                                    <RenderSettings settings={subArgumentsToRender}/>
                                </>
                            );
                        }}
                    </Field>
                );
            })}
        </>
    );
}
