export enum ArgumentType {
    dropdown = 'dropdown',
    file = 'file',
    group = 'group',
    hidden = 'hidden',
    multifile = 'multifile',
    multiselect = 'multiselect',
    number = 'number',
    radio = 'radio',
    sequence = 'sequence',
    text = 'text',
    textarea = 'textarea',
    textFile = 'text-file',
    toggle = 'toggle',
    dragAndDropFile = 'drag-and-drop-file',
}

export type SettingUuid = string;

export interface ISetting {
    default_file: string | null;
    default_value: string;
    description: string;
    do_not_pass_if_value_empty: boolean;
    exclude_value: boolean;
    group_arguments: ISetting[];
    group_separator: string;
    group_argument_separator: string;
    key: string;
    key_value_separator: string;
    options: ISettingOption[];
    order: number;
    public_id: SettingUuid;
    render_type: ArgumentType;
    required: boolean;
    sub_argument_condition: null | string;
    sub_arguments: ISetting[];
}

export interface ISettingOption {
    key: string;
    value: string;
}
