import * as classNames from 'classnames';
import React from 'react';
import { Button, FileInput, Intent } from '@blueprintjs/core';
import { FieldProps } from 'formik';
import { saveFileListToFormikField } from './utils';

interface IProps extends FieldProps {
    defaultValue: string;
    allowMultipleFiles?: boolean;
}

const SimpleFileInput: React.FC<IProps> = (props: IProps) => {
    const { field, form, defaultValue, allowMultipleFiles } = props;
    const fileList = typeof field.value === 'object' ? field.value as FileList : null;
    const firstFile = fileList ? fileList.item(0) : null;

    const [isDrag, setIsDrag] = React.useState<boolean>(false);

    const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setIsDrag(false);
        const files = event.dataTransfer.files;
        saveFileListToFormikField(files, field, form, allowMultipleFiles);
    };

    const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
        setIsDrag(true);
        event.dataTransfer.dropEffect = 'copy';
    };

    const text = fileList ? Array.from(fileList).map((file) => file.name).join(', ') : defaultValue ?
        defaultValue : 'Choose file...';

    return (
        <>
            <FileInput
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={() => setIsDrag(false)}
                style={{ height: 34 }}
                className={classNames({ 'border-2 rounded-md border-emerald-500': isDrag })}
                title={text}
                text={text}
                hasSelection={!!firstFile}
                inputProps={{ name: field.name, onBlur: field.onBlur, multiple: !!allowMultipleFiles }}
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    saveFileListToFormikField(event.currentTarget.files, field, form, allowMultipleFiles)
                }
            />
            {!field.value ? null :
                <Button
                    icon='trash'
                    className='mb-1'
                    intent={Intent.DANGER}
                    minimal={true}
                    onClick={() => form.setFieldValue(field.name, '')}
                    style={{ marginLeft: 3 }}
                />
            }
        </>
    )
};

export default SimpleFileInput;
