import { FieldInputProps, FormikProps } from 'formik';

export function saveFileListToFormikField(
    files: FileList | null,
    field: FieldInputProps<any>,
    form: FormikProps<any>,
    allowMultipleFiles: boolean,
) {
    if (!files) {
        form.setFieldValue(field.name, '');
    } else {
        if (!allowMultipleFiles && files.length > 1) {
            form.setFieldTouched(field.name, true, false);
            form.setFieldError(field.name, 'Only a single file is accepted');
        }
        form.setFieldValue(field.name, files);
    }
}
